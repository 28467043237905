export default [
    {
        title: 'Dashboards',
        icon: 'HomeIcon',
        route: 'home',
    },
    {
        title: 'Accounts',
        route: 'account-page',
        icon: 'UsersIcon',
        children: [
            {
                title: 'Users',
                route: 'list-user-page',
            },
            {
                title: 'Admins',
                route: 'list-admin-page',
            },
        ],
    },
    {
        title: 'Domains',
        route: 'domain-page',
        icon: 'ServerIcon',
    },
    {
        title: 'Groups',
        route: 'group-page',
        icon: 'FolderIcon',
    },
    {
        title: 'Logs',
        route: 'log-page',
        icon: 'DatabaseIcon',
    },
]
